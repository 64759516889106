import { XF } from "./XF";
import { openSignupModal } from "./quick-reply";

export const NestedPostReplies = {
  displayReplyEditorForPost(
    postId: string,
    event?: Event,
    callback?: (e: Event) => void
  ) {
    const replyEditor = document.querySelector(`#js-reply-to-post-${postId}`);
    if (replyEditor) {
      replyEditor.classList.add("visible");
      if (callback && event) {
        callback(event);
      }
    } else {
      const $editorContainer = $(`#js-reply-to-post-container-${postId}`);
      const uri = `/posts/${postId}/load-nested-reply`;

      XF.ajax(
        "post",
        uri,
        (data: { html: { content: string; js: string[]; css: string[] } }) => {
          XF.setupHtmlInsert(data.html, ($html) => {
            $editorContainer.replaceWith($html);
            this.attachEditorClickListeners($html.get(0));
            XF.activate($html);
            XF.focusEditor($html);
            if (callback && event) {
              callback(event);
            }
          });
        }
      );
    }
  },

  attachEditorClickListeners(editorForm?: HTMLElement) {
    if (!editorForm) return;
    editorForm.addEventListener("preview:shown", this.onPreviewShown);

    const editButton = editorForm.querySelector(".js-edit-button");
    editButton?.addEventListener("click", this.onEditButtonClick);

    const mobileMask = editorForm.querySelector(".mobile-mask");
    mobileMask?.addEventListener("click", this.onMobileMaskClick);

    editorForm
      .querySelector(".quick-reply__sign-up-button")
      ?.addEventListener("click", openSignupModal);
  },

  hideReplyEditorForPost(postId: number) {
    const replyEditor = document.querySelector(`#js-reply-to-post-${postId}`);
    replyEditor?.classList.remove("visible");
  },

  onPreviewShown(event: Event) {
    const target = <HTMLButtonElement>event.currentTarget;
    const editor = target.querySelector(".editor-wrapper");
    const previewButton = target.querySelector(".js-preview-button");
    const editButton = target.querySelector(".js-edit-button");

    editor?.classList.add("hidden");
    previewButton?.classList.add("hidden");
    editButton?.classList.remove("hidden");
  },

  onEditButtonClick(event: Event) {
    const target = <HTMLButtonElement>event.currentTarget;
    const replyContainer = target.closest(".MessageCard__reply-to-post");
    const editor = replyContainer?.querySelector(".editor-wrapper");
    const preview = replyContainer?.querySelector(".js-previewContainer");
    const previewButton = replyContainer?.querySelector(".js-preview-button");

    editor?.classList.remove("hidden");
    preview?.classList.remove("is-active");
    previewButton?.classList.remove("hidden");
    target.classList.add("hidden");
  },

  onMobileMaskClick(event: Event) {
    const target = <HTMLButtonElement>event.currentTarget;
    if (event.target === event.currentTarget) {
      const editorContainer = target.closest(".nested-post-reply-container");
      editorContainer?.classList.remove("visible");
    }
  },
};
