import { California } from "./California";
import { XF } from "./XF";
import { loadChildGalleries } from "./california-gallery";
import { displaySignatureChevron } from "./message-signatures";
import { NestedPostReplies } from "./nested-post-reply";

XF.Element.extend("multi-quote", {
  initButton() {
    this.mqStorage = XF.LocalStorage.getJson(this.options.storageKey);
    if (this.hasQuotesStored()) {
      $("#mq-number").text(`(${getTotalQuotes(this.mqStorage)})`);
      this.$target.show();
    }
    this.$target.on("click", XF.proxy(this, "buttonClick"));
  },

  loadOverlay(data) {
    if (data.html) {
      XF.setupHtmlInsert(data.html, ($html, container) => {
        const $overlay = XF.getOverlayHtml({
          html: $html,
          title: container.h1 || container.title,
        });
        $overlay
          .find(".js-removeMessage")
          .on("click", XF.proxy(this, "removeMessage"));
        $overlay
          .find(".js-quoteMessages")
          .on("click", XF.proxy(this, "quoteMessages"));
        if (window.innerWidth > 900) {
          $overlay.css("max-width", "500px");
        }
        this.mqOverlay = XF.showOverlay($overlay);
      });
    }
  },

  updateMultiQuote() {
    $("#mq-number").text(`(${getTotalQuotes(this.mqStorage)})`);
    XF.LocalStorage.setJson(this.options.storageKey, this.mqStorage, true);
    this.updateButtonState();
  },
});

XF.Element.extend("select-to-quote", {
  createButton($selectionContainer, id: number) {
    const $message = $selectionContainer.closest(this.options.messageSelector);
    const $tooltip = $("<span />");

    const $mqButton = $message.find(".actionBar-action.js-multiQuote").clone();
    if ($mqButton.length) {
      $mqButton
        .attr("title", "")
        .removeClass("is-selected")
        .data("mqAction", "add")
        .css({
          marginLeft: 0,
        })
        .on("s2q:click", XF.proxy(this, "buttonClicked"));

      $tooltip.append($mqButton);
      $tooltip.append(document.createTextNode(" | "));
    }

    let $quoteButton = $message
      .find('.actionBar-action[data-xf-click="quote"]')
      .attr("title", "")
      .clone()
      .css({
        marginLeft: 0,
      })
      .on("s2q:click", XF.proxy(this, "buttonClicked"));

    if ($quoteButton.length === 0) {
      // try to find one on the nested UI
      $quoteButton = $message
        .find(".js-partial-quote-button")
        .clone()
        .on("s2q:click", XF.proxy(this, "buttonClicked"));
    }

    $tooltip.append($quoteButton);

    this.tooltip = new XF.TooltipElement($tooltip, {
      html: true,
      placement: "bottom",
    });
    this.tooltipId = id;
  },
});

function getTotalQuotes(quoteArray) {
  return Object.keys(quoteArray)
    .map((post) => quoteArray[post].filter((e) => e !== null).length)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

XF.Element.extend("quick-reply", {
  afterSubmit(e: Event, data) {
    if (data.errors || data.exception) {
      return;
    }
    e.preventDefault();

    if (data.redirect) {
      XF.redirect(data.redirect);
      return;
    }

    if (data.nested_post_reply) {
      const $newNestedReplyContainer = $(`
        #js-new-reply-container-${data.parent_post_id}
      `);

      if ($newNestedReplyContainer && $newNestedReplyContainer.length > 0) {
        XF.setupHtmlInsert(data.html, ($html) => {
          $newNestedReplyContainer?.replaceWith($html);
        });

        NestedPostReplies.hideReplyEditorForPost(data.parent_post_id);
      } else {
        // the reply was added from the reply box at the bottom of the page
        const $messagesContainer = this.getMessagesContainer();
        XF.setupHtmlInsert(data.html, ($html) => {
          $messagesContainer.append($html);
        });
      }
    } else {
      this.$target.find('input[name="last_date"]').val(data.lastDate);
      this.getMessagesContainer().find(".js-newMessagesIndicator").remove();

      this.insertMessages(data.html);
    }

    XF.clearEditorContent(this.$target);

    const editor = XF.getEditorInContainer(this.$target);
    if (editor && XF.Editor && editor instanceof XF.Editor) {
      editor.blur();
    }

    const $filesContainer = this.$target.find(this.options.filesContainer);

    if ($filesContainer.length) {
      $filesContainer.removeClass("is-active");

      const $insertAllRow = $filesContainer.find(this.options.insertAllRow);
      const $files = $filesContainer.find(this.options.fileRow);

      if ($insertAllRow.length) {
        $insertAllRow.removeClass("is-active");
      }
      if ($files.length) {
        $files.remove();
      }
    }

    this.$target.trigger("preview:hide", [this]); // XF.PreviewClick listens for this

    if (this.options.submitHide) {
      const $submitHide = XF.findRelativeIf(
        this.options.submitHide,
        this.$target
      );
      $submitHide.hide();
    }
  },

  insertMessages(dataHtml) {
    XF.Message.insertMessages(
      dataHtml,
      this.getMessagesContainer(),
      this.options.ascending,
      ($messages) => {
        const $message = $messages.first();
        if ($message && $message.length) {
          const dims = $message.dimensions();
          const windowTop = $(window).scrollTop() || 0;
          const windowBottom = windowTop + $(window).height();

          if (dims.top < windowTop + 50 || dims.top > windowBottom) {
            $("html, body").animate(
              { scrollTop: Math.max(0, dims.top - 60) },
              200
            );
          }
          loadChildGalleries($message);
          displaySignatureChevron($message[0]);
        }
      }
    );
    $('.message--quickReply [name="is_business_reply"]').prop("checked", false);
    $("#replies-header").removeClass("replies-header-hidden");
    let replyHeader = $(".pro-content-reply-count").html();
    const replyCount = parseInt(replyHeader, 10);
    const replySuffix = replyCount === 0 ? " Reply" : " Replies";
    replyHeader = replyCount + 1 + replySuffix;
    $(".pro-content-reply-count").html(replyHeader);
  },
});

XF.Element.extend("quick-edit", {
  parentClass: undefined as unknown as typeof XF.QuickEditClick,
  init() {
    this.parentClass = Object.getPrototypeOf(Object.getPrototypeOf(this));
    const parentInit = this.parentClass.init.bind(this);
    parentInit();
  },
  editSubmit(e: Event, data) {
    if (data.errors || data.exception) {
      return;
    }

    e.preventDefault();

    if (data.message) {
      XF.toast(data.message);
    }

    const { $editorTarget } = this;

    XF.setupHtmlInsert(data.html, ($html) => {
      let target = this.options.editorTarget;
      target = target.replace(/<|\|/g, "").replace(/#[a-zA-Z0-9_-]+\s*/, "");

      const $message = $html.find(target);

      $message.hide();
      $editorTarget.replaceWith($message);
      this.$editorTarget = $message;
      XF.activate($message);

      this.stopEditing(false, () => {
        $message.xfFadeDown();

        this.$editForm.trigger("quickedit:editcomplete", data);
      });
      loadChildGalleries($message[0]);
      displaySignatureChevron($message[0]);
    });
  },

  click(e: Event) {
    // need to binds `this` context to the XF function so it will execute properly
    const parentFunction = this.parentClass.click.bind(this);
    parentFunction(e);
    this.hideCommentButtion();
  },

  stopEditing(showMessage: boolean, onComplete: () => void) {
    const parentFunction = this.parentClass.stopEditing.bind(this);
    parentFunction(showMessage, onComplete);
    this.showCommentButtion();
  },

  hideCommentButtion() {
    const commentButton = document.getElementById(
      this.$target.data("commentButton")
    );
    if (commentButton) {
      commentButton.style.visibility = "hidden";
    }
  },

  showCommentButtion() {
    const commentButton = document.getElementById(
      this.$target.data("commentButton")
    );
    if (commentButton) {
      commentButton.style.visibility = "visible";
    }
  },
});

XF.Event.extend("quote", {
  options: {
    quoteHref: null,
    postId: null,
    nestedPostReply: 0,
    editor: ".js-quickReply .js-editor",
  },
  init() {
    if (this.options.nestedPostReply) {
      this.options.editor = `#js-reply-to-post-${this.options.postId}`;
    }
    this.parentClass = Object.getPrototypeOf(Object.getPrototypeOf(this));
    const parentInit = this.parentClass.init.bind(this);
    parentInit();
  },
  click(e: Event) {
    e.preventDefault();
    const parentFunction = this.parentClass.click.bind(this);

    if (this.options.nestedPostReply) {
      NestedPostReplies.displayReplyEditorForPost(
        this.options.postId,
        e,
        parentFunction
      );
    } else {
      parentFunction(e);
    }
  },
});

California.NestedPostReplyClick = XF.Event.newHandler({
  eventNameSpace: "CaliforniaNestedPostReplyClick",

  init() {},

  click(e: Event) {
    e.preventDefault();

    const target = <HTMLButtonElement>e.currentTarget;
    const { postId } = target.dataset;
    if (postId) {
      NestedPostReplies.displayReplyEditorForPost(postId);
    }
  },
});

California.ShareUrlClick = XF.Event.newHandler({
  eventNameSpace: "CaliforniaShareUrlClick",
  copiedToClipboardTooltipTrigger: null,

  init() {},

  click(e: Event) {
    e.preventDefault();

    const target = <HTMLButtonElement>e.currentTarget;
    const { href, threadTitle, postNumber } = target.dataset;

    if (!href) {
      return;
    }

    // do not use windows share, at the moment it is a bad user experience
    const isWindows = /Windows/.test(navigator.userAgent);
    if (isWindows) {
      copyToClipboard(href);
      return;
    }

    interface ShareObject {
      title: string;
      text?: string;
      url?: string;
    }

    const shareObj: ShareObject = {
      title: `${threadTitle} Post #${postNumber}`,
    };
    // iOS doesn't support url share
    if (XF.isIOS()) {
      shareObj.text = href;
    } else {
      shareObj.url = href;
    }

    if (navigator.canShare && navigator.canShare(shareObj)) {
      navigator.share(shareObj).catch((error) => {
        // if user canceled share dialog, do not fallback to copying to clipboard
        if (error.toString().includes("AbortError")) {
          return;
        }
        copyToClipboard(href);
      });
    } else {
      copyToClipboard(href);
    }
  },
});

function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(() => {
    XF.toast(XF.phrase("link_copied_to_clipboard"));
  });
}

XF.Event.register(
  "click",
  "nested-post-reply",
  "California.NestedPostReplyClick"
);

XF.Event.register("click", "share-url", "California.ShareUrlClick");
