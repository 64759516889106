// should match css class signature-collapsed & signature-overlay height
const expandSignature = (signature: ReturnType<typeof $>) => {
  signature.removeClass("signature-collapsed");

  // switch icon to closed icon
  signature.find(".signature-view").addClass("signature-view--expanded");

  signature.find(".signature-image").removeClass("signature-preview");
};

const closeSignature = (signature: ReturnType<typeof $>) => {
  signature.addClass("signature-collapsed");

  // switch icon to expand icon
  signature.find(".signature-view").removeClass("signature-view--expanded");

  signature.find(".signature-image").addClass("signature-preview");
};

function toggleSignatureView(
  target: ReturnType<typeof $>,
  status: "expanded" | "closed"
) {
  const lbContainer = target.find(".lbContainer");
  const signatureWrapper = <HTMLElement>target[0];
  signatureWrapper.classList.toggle("messageSignature-gradient");
  if (status === "expanded") {
    expandSignature(target);
    lbContainer.addClass("lbContainer--canZoom");
  } else {
    closeSignature(target);
    lbContainer.removeClass("lbContainer--canZoom");
  }
  target.attr("status", status);
}

$(document.body).on("click touchend", ".signature-content-wrapper", (e) => {
  const currentTarget = $(e.currentTarget);
  const { target } = e;
  const parent = currentTarget.parent();

  // If signature is closed, prevent default lightbox behaviours when clicking on an image
  if (
    parent.attr("status") === "closed" &&
    (target.tagName === "IMG" || target.tagName === "PICTURE")
  ) {
    e.preventDefault();
  }

  // when clicked on a non-link and we are on desktop, expand the signature
  if (target.tagName !== "A" && window.innerWidth > 900) {
    if (parent.attr("status") === "closed") {
      toggleSignatureView(parent, "expanded");
    } else {
      toggleSignatureView(parent, "closed");
    }
  }
});

$(document.body).on("click touchend", ".signature-view", (e) => {
  e.preventDefault();
  const parent = $(e.currentTarget).parent();
  if (parent.attr("status") === "closed") {
    toggleSignatureView(parent, "expanded");
  } else {
    toggleSignatureView(parent, "closed");
  }
});

/**
 * Determines if the chevron for expanding the signature will be displayed or not
 */
export function displaySignatureChevron(target?: HTMLElement) {
  // Get all bbWrapper divs within the signature wrapper divs
  const element = target || document;
  const messageSignatures = Array.from(
    element.querySelectorAll("#message-signature")
  );

  // Find all bbWrapper divs that are taller than 40px
  messageSignatures.forEach((messageSignature) => {
    const bbWrapper = messageSignature.querySelector(".bbWrapper");
    if (!bbWrapper) return;
    const doesImageExist = bbWrapper.querySelector("img");

    if (bbWrapper.clientHeight > 40 || doesImageExist != null) {
      const signatureView =
        messageSignature.querySelector<HTMLDivElement>("#signature-view");
      if (signatureView) {
        signatureView.style.display = "initial";
        messageSignature.classList.add("messageSignature-gradient");
      }
    } else {
      const signatureContentWrapper =
        messageSignature.querySelector<HTMLDivElement>(
          "#signature-content-wrapper"
        );
      if (!signatureContentWrapper) return;
      signatureContentWrapper.style.cursor = "default";
    }
  });
}

$(() => {
  displaySignatureChevron();
});
